<template data-app>
  <div class="dashboard-content">
    <!-- Content -->
    <div class="card">
      <div class="filters" style="padding: 10px 10px 0px 10px">
        <h3 class="text-center">
          Publicidad<v-btn
            color="#003a56"
            dark
            class="mb-2"
            style="float: right"
            @click.prevent="handleCreate()"
            >Crear
          </v-btn>
        </h3>

        <v-row style="margin: 0px">
          <v-col cols="12" style="padding-top: 0px">
            <label>Filtrar por:</label>

            <v-form ref="formCompany" v-model="valid" style="width: 100%" class="row">
              <v-col cols="12" lg="11" md="10" sm="8">
                <v-row>
                  <v-col cols="12" lg="3" md="4" sm="6" style="padding: 1px 2px 1px 2px">
                    <v-text-field
                      dense
                      solo
                      hide-details="auto"
                      label="Búsqueda"
                      v-model="filters.search"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="3" md="4" sm="6" style="padding: 1px 2px 1px 2px">
                    <multiselect
                      track-by="id"
                      label="name"
                      deselect-label=""
                      select-label=""
                      placeholder="Cliente"
                      :custom-label="fullName"
                      v-model="filters.userId"
                      :options="users"
                      :multiple="false"
                      :close-on-select="true"
                      :clear-on-select="false"
                      :preserve-search="false"
                    ></multiselect>
                  </v-col>
                  <!--<v-col cols="12" lg="3" md="3" sm="6" style="padding: 1px 2px 1px 2px">
										<multiselect
											track-by="id"
											label="name"
											deselect-label=""
											select-label=""
											placeholder="País"
											:custom-label="countryName"
											v-model="filters.countryId"
											:options="countries"
											:multiple="false"
											:close-on-select="true"
											:clear-on-select="false"
											:preserve-search="false"
										></multiselect>
									</v-col>-->
                  <v-col cols="12" lg="3" md="3" sm="6" style="padding: 1px 2px 1px 2px">
                    <multiselect
                      track-by="id"
                      label="name"
                      deselect-label=""
                      select-label=""
                      placeholder="Tipo"
                      v-model="filters.type"
                      :options="typeOptions"
                      :multiple="false"
                      :close-on-select="true"
                      :clear-on-select="true"
                      :preserve-search="false"
                    ></multiselect>
                  </v-col>

                  <v-col cols="12" lg="1" md="2" sm="6" style="padding: 1px 2px 1px 2px">
                    <multiselect
                      style="min-width: 80px"
                      track-by="id"
                      label="name"
                      deselect-label=""
                      select-label=""
                      placeholder="Activa"
                      v-model="filters.active"
                      :options="activeOptions"
                      :multiple="false"
                      :close-on-select="true"
                      :clear-on-select="false"
                      :preserve-search="false"
                    ></multiselect>
                  </v-col>
                </v-row>
              </v-col>
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="#003a56"
                style="min-width: auto; position: absolute; right: 50px; height: 25px"
                dark
                class="px-1 py-1"
                v-if="filterActivated"
                @click.prevent="
                  filterActivated = false
                  cleanFilters()
                  getAdvertisements()
                "
              >
                <v-icon dark> mdi-delete </v-icon>
              </v-btn>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    color="#003a56"
                    style="min-width: auto; position: absolute; right: 10px; height: 25px"
                    dark
                    class="px-1 py-1"
                    @click.prevent="
                      filterActivated = true
                      getAdvertisements()
                    "
                    ><v-icon dark> mdi-filter </v-icon>
                  </v-btn>
                </template>
                <span>Filtrar</span>
              </v-tooltip>
            </v-form>
          </v-col>
        </v-row>
      </div>
      <simple-list
        :title="''"
        :dense="false"
        :headers="headers"
        :items="items"
        :totalItems="totalItems"
        :itemsPerPage="itemsPerPage"
        :loadingText="loadingText"
        :hideFooter="true"
        :multiSort="false"
        :loading="loading"
        :class="'mb-4'"
        :sortable="true"
        :page="page"
        :pages="pages"
        :extraButtoms="false"
        :showCreate="true"
        :showDelete="true"
        :buttonLink="'empresas/ficha'"
        :orderOptions="orderOptions"
        @create:item="handleCreate()"
        @edit:item="handleEdit($event)"
        @delete:item="deleteItem($event)"
        @update:page="pagination($event)"
        @orderEvent="handleOrder($event)"
      />
    </div>
    <modal name="crudModal" :height="'75%'" :width="'80%'" :adaptive="true">
      <h3 class="text-center mt-2" v-if="flow == 'create'">Crear Publicidad</h3>
      <h3 class="text-center mt-2" v-if="flow == 'edit'">Editar Publicidad</h3>
      <div class="row mx-1" v-if="flow == 'edit'">
        <div class="col-lg-4 col-sm-6">
          <div class="card mb-4">
            <div class="card-body text-center">
              <i class="i-Add-User"></i>
              <h3 class="text-primary mb-1">
                {{ item.advertisement_stats_sum_print_count }}
              </h3>
              <p class="text-muted mt-2 mb-0">Visualizaciones</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6">
          <div class="card mb-4">
            <div class="card-body text-center">
              <i class="i-Add-User"></i>
              <h3 class="text-primary mb-1">
                {{ item.advertisement_stats_sum_click_count }}
              </h3>
              <p class="text-muted mt-2 mb-0">Clicks</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6">
          <div class="card mb-4">
            <div class="card-body text-center">
              <i class="i-Add-User"></i>
              <h3 class="text-primary mb-1">
                {{ item.advertisement_stats_sum_print_time / 60 }}
              </h3>
              <p class="text-muted mt-2 mb-0">Tiempo de exposición (m)</p>
            </div>
          </div>
        </div>
      </div>
      <div class="card" style="padding: 2rem; margin: 0px 20px 20px 20px">
        <h5 style="margin-bottom: 20px">Datos generales</h5>

        <div class="row">
          <v-form ref="formCompany" v-model="valid" style="width: 100%" class="row">
            <v-col cols="12" lg="4" md="6" sm="12">
              <multiselect
                track-by="id"
                :rules="[(v) => !!v || 'El responsable es requerido']"
                label="name"
                placeholder="Seleccionar responsable"
                @input="getCompanies(userId)"
                :custom-label="fullName"
                v-model="userId"
                :options="users"
                :multiple="false"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="false"
              ></multiselect>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-select
                :items="companies"
                :item-text="'name'"
                :item-value="'id'"
                v-model="companyId"
                label="Empresa"
                type="text"
              ></v-select
            ></v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                required
                :rules="descriptionRules"
                label="Nombre"
                v-model="name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                required
                :rules="[rules.required, rules.url, rules.max]"
                label="Redirección"
                v-model="redirection"
                type="text"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                required
                label="Orden"
                v-model="order"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-select
                :rules="[(v) => !!v || 'El tipo de publicidad es requerido']"
                :items="types"
                :item-text="'name'"
                :item-value="'code'"
                v-model="type"
                label="Tipo"
                type="text"
                :hint="typeMessage"
              ></v-select
            ></v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                required
                :rules="descriptionRules"
                label="Fecha de incio de destacado"
                v-model="outstandingStart"
                type="date"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                required
                :rules="descriptionRules"
                label="Fecha final de destacado"
                v-model="outstandingEnd"
                type="date"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                required
                :rules="descriptionRules"
                label="Fecha de vencimiento"
                v-model="expiratedAt"
                type="date"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <!--<v-select
									:rules="[(v) => !!v || 'El país es requerido']"
									:items="filteredCountries"
									:item-text="'name'"
									:item-value="'id'"
									:menu-props="{ maxHeight: '300' }"
									:counter="filteredCountries.length"
									hide-selected
									chips
									deletable-chips
									multiple
									v-model="selectedCountries"
									label="Países"
									type="text"
								>
									<template v-slot:prepend-item>
										<v-col cols="12">
											<v-text-field
												required
												label="Buscar país"
												v-model="countryText"
												type="text"
											></v-text-field>
										</v-col>
										<v-divider class="mt-0"></v-divider>
									</template>
									<template v-slot:selection="{ item }">
										<v-chip>
											<span>{{ item }}</span>
										</v-chip>
									</template></v-select
								>-->
              <multiselect
                track-by="id"
                label="name"
                placeholder="Seleccionar países"
                v-model="selectedCountries"
                :custom-label="nameWithIso2"
                :options="filteredCountries"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="false"
              ></multiselect>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-file-input
                v-model="image"
                :rules="fileRules"
                chips
                ref="file"
                show-size
                accept="image/png, image/jpeg, image/jpg, image/gif"
                :label="'Seleccione la imagen'"
              ></v-file-input>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-checkbox label=" Activa" v-model="active"></v-checkbox>
            </v-col>
          </v-form>
        </div>
      </div>

      <div
        v-if="flow == 'edit'"
        class="card"
        style="
          padding: 1rem;
          margin: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <h5>Vista previa</h5>
        <div
          class="container"
          v-if="item.type == 'horizontal1' || item.type == 'horizontal2'"
        >
          <span style="font-size: 11px">Publicidad</span>
          <a :href="redirection" class="btn" target="_blank" rel="noopener">
            <img
              v-if="item.image && item.image.route"
              :src="item.image.route"
              :alt="item.name"
              style="width: 100%"
          /></a>
        </div>
        <div
          class="container"
          v-if="item.type == 'vertical1' || item.type == 'vertical2'"
        >
          <div class="row">
            <div class="col-lg-9 col-md-8 col-sm-12"></div>
            <div class="col-lg-3 col-md-4 col-sm-12">
              <div class="row">
                <span class="text-center" style="font-size: 11px; margin: auto"
                  >Publicidad</span
                >
              </div>

              <div class="row">
                <a :href="redirection" class="btn" target="_blank" rel="noopener">
                  <img
                    v-if="item.image && item.image.route"
                    :src="item.image.route"
                    :alt="item.name"
                    style="width: 100%"
                /></a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <v-spacer></v-spacer>
      <v-col cols="12" style="margin-bottom: 20px; height: 10%">
        <div class="row">
          <v-btn
            style="margin: auto; margin-top: 10px; color: #fff"
            v-if="flow == 'create'"
            :disabled="!valid"
            color="#003a56"
            @click="createItem()"
          >
            Crear
          </v-btn>
          <v-btn
            style="margin: auto; margin-top: 10px; color: #fff"
            v-if="flow == 'edit'"
            :disabled="!valid"
            color="#003a56"
            @click="saveItem()"
          >
            Guardar
          </v-btn>

          <v-btn
            style="margin: auto; margin-top: 10px; color: #fff"
            color="#003a56"
            @click="reset"
          >
            Limpiar
          </v-btn>
          <v-btn
            style="margin: auto; margin-top: 10px; color: #fff"
            color="#003a56"
            @click="$modal.hide('crudModal')"
          >
            Cancelar
          </v-btn>
        </div>
      </v-col>
    </modal>
  </div>
</template>

<script>
  const date = new Date()
  import SimpleList from 'Components/Listing/SimpleList.vue'
  import Multiselect from 'vue-multiselect'

  export default {
    name: 'Advertisements',
    components: {
      SimpleList,
      Multiselect,
    },
    data() {
      return {
        config: {
          headers: {},
        },
        companies: [],
        loading: false,
        headers: [
          { text: 'Cliente', value: 'userFullName' },
          { text: 'Nombre', value: 'name' },
          { text: 'Activa', value: 'activeText' },
          { text: 'Tipo', value: 'typeText' },
          { text: 'Expiración', value: 'expirated_at' },
          { text: 'Acciones', value: 'actions', align: 'center', width: '110px' },
        ],
        types: [
          { code: 'horizontal1', name: 'Banner horizontal principal' },
          { code: 'horizontal2', name: 'Banner horizontal secundario' },
          { code: 'vertical1', name: 'Banner vertical lateral' },
          { code: 'vertical2', name: 'Banner vertical cuadrado' },
        ],
        items: [],
        users: [],
        page: 1,
        pages: 1,
        item: {},
        totalItems: 0,
        itemsPerPage: 10,
        flow: '',
        filterActivated: false,
        orderBy: { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
        orderOptions: [
          { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
          { id: 2, name: 'Antiguos', order: 'created_at', by: 'asc' },
          { id: 3, name: 'Nombre', order: 'name', by: 'asc' },
          { id: 4, name: 'Última Edición', order: 'updated_at', by: 'desc' },
        ],
        filters: {
          userId: null,
          search: '',
          outstanding: null,
          type: null,
          active: null,
          countryId: null,
        },
        activeOptions: [
          { id: null, name: 'Todas' },
          { id: 1, name: 'Activas' },
          { id: 0, name: 'Inactivas' },
        ],
        typeOptions: [
          { id: null, name: 'Todos' },
          { id: 'horizontal1', name: 'Banner horizontal principal' },
          { id: 'horizontal2', name: 'Banner horizontal secundario' },
          { id: 'vertical1', name: 'Banner vertical lateral' },
          { id: 'vertical2', name: 'Banner vertical cuadrado' },
        ],
        active: false,
        name: '',
        redirection: '',
        userId: '',
        countryId: '',
        companyId: '',
        order: 1,
        type: 'vertical',
        image: null,
        outstandingStart: new Date().toISOString().split('T')[0],
        outstandingEnd: new Date(date.setMonth(date.getMonth() + 1))
          .toISOString()
          .split('T')[0],
        expiratedAt: new Date(date.setMonth(date.getMonth() + 1))
          .toISOString()
          .split('T')[0],
        countries: [],
        selectedCountries: [],
        countryText: '',
        loadingText: 'Cargando registros',
        valid: true,
        descriptionRules: [
          (v) => !!v || 'La descripción es requerida',
          (v) => v.length <= 100 || 'La descripción debe poser menos de 100 caracteres',
        ],
        rules: {
          required: (v) => !!v || 'Requerido.',
          min: (v) => v.length >= 8 || '8 caracteres como mínimo',
          max: (v) => v.length <= 200 || 'Debe poser menos de 200 caracteres',
          url: (v) => this.isURL(v) || 'La URL es inválida',
        },
        fileRules: [
          (value) =>
            !value ||
            value.size < 5000000 ||
            'El peso del archivo debe ser inferior a 5MB',
        ],
      }
    },
    created() {
      this.getAdvertisements()
      this.getCountries()
      this.getUsers()
    },
    computed: {
      likesAllCountry() {
        return this.selectedCountries.length === this.filteredCountries.length
      },
      likeSomeCountry() {
        return this.selectedCountries.length > 0 && !this.likesAllCountry
      },
      icon() {
        if (this.likesAllCountry) return 'mdi-close-box'
        if (this.likeSomeCountry) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
      filteredCountries() {
        if (this.countryText != '') {
          return this.countries.filter((country) =>
            country.name.toLowerCase().includes(this.countryText)
          )
        } else {
          return this.countries
        }
      },
      countryCode() {
        if (localStorage.getItem('selectedCountry')) {
          return JSON.parse(localStorage.getItem('selectedCountry')).iso2
        } else {
          return ''
        }
      },
      typeMessage() {
        switch (this.type) {
          case 'vertical1':
            return 'La imagen será redimensionada a 300x400px'
          case 'vertical2':
            return 'La imagen será redimensionada a 250x250px'
          case 'horizontal1':
            return 'La imagen será redimensionada a 1920x540px'
          case 'horizontal2':
            return 'La imagen será redimensionada a 1920x300px'
          default:
            return ''
        }
      },
    },
    methods: {
      handleOrder(orderBy) {
        this.orderBy = orderBy
        this.getAdvertisements()
      },
      fullName({ name, lastname, email }) {
        if (name && name != 'Todos') {
          if (lastname && email) {
            return `${name} ${lastname} | ${email}`
          } else {
            return name
          }
        } else {
          return name
        }
      },
      isURL(str) {
        if (!str || str === '') return true
        let url
        try {
          url = new URL(str)
        } catch (_) {
          return false
        }

        return url.protocol === 'http:' || url.protocol === 'https:'
      },
      nameWithIso2({ name, iso2 }) {
        return `${name} (${iso2})`
      },
      cleanFilters() {
        this.filters = {
          userId: null,
          search: '',
          outstanding: null,
          type: null,
          active: null,
          countryId: null,
        }
      },
      toggle() {
        this.$nextTick(() => {
          if (this.likesAllCountry) {
            this.selectedCountries = []
          } else {
            this.selectedCountries = this.filteredCountries.slice()
          }
        })
      },
      async getAdvertisements() {
        this.loading = true
        let queryParams = ''
        let orderString = ''
        if (this.orderBy.order && this.orderBy.by) {
          orderString = '&order=' + this.orderBy.order + '&by=' + this.orderBy.by
        }
        if (this.filterActivated) {
          let searchString = ''
          let userString = ''
          let typeString = ''
          //let countryString = ''
          //let outstandingString = ''
          let activeString = ''
          if (this.filters.search != '') {
            searchString = '&search=' + this.filters.search
          }
          if (this.filters.userId && this.filters.userId.id) {
            userString = '&user_id=' + this.filters.userId.id
          }
          if (this.filters.type && this.filters.type.id) {
            typeString = '&type=' + this.filters.type.id
          }
          /*if (this.filters.countryId && this.filters.countryId.id) {
						countryString = '&country_id=' + this.filters.countryId.id
					}
					if (this.filters.outstanding && this.filters.outstanding.id) {
						outstandingString = '&outstanding=' + this.filters.outstanding.id
					}*/
          if (this.filters.active && this.filters.active.id) {
            activeString = '&active=' + this.filters.active.id
          }
          queryParams = searchString + userString + activeString + typeString
        }
        axios
          .get(
            process.env.VUE_APP_API_DIRECTORY +
              'advertisements?page=' +
              this.page +
              '&withStats=1' +
              orderString +
              queryParams
          )
          .then((response) => {
            this.items = response.data.data
            if (this.items.length < 1) {
              this.loadingText = 'No hay registros'
            }
            this.totalItems = response.data.total
            this.itemsPerPage = response.data.per_page
            this.page = response.data.from
            this.pages = response.data.last_page
            this.items.map((x) => {
              x.activeText = x.active === 1 ? 'Si' : 'No'
              switch (x.type) {
                case 'vertical1':
                  x.typeText = 'Banner vertical lateral'
                  break
                case 'vertical2':
                  x.typeText = 'Banner vertical cuadrado'
                  break
                case 'horizontal1':
                  x.typeText = 'Banner horizontal principal'
                  break
                case 'horizontal2':
                  x.typeText = 'Banner horizontal secundario'
                  break
                default:
                  x.typeText = ''
                  break
              }
              x.userFullName = x.user.name + ' ' + x.user.lastname + ' | ' + x.user.email
              x.expirated_at = x.expirated_at.split(' ')[0]
            })
            this.loading = false
          })
      },

      async getCountries() {
        this.loading = true
        axios
          .get(process.env.VUE_APP_API_DIRECTORY + 'countries?limit=300' + this.countryId)
          .then((response) => {
            this.countries = response.data
            this.countries.map((country) => {
              country.code = country.id
            })
            this.selectedCountries.push(
              this.countries.filter((country) => country.iso2 == this.countryCode).shift()
            )
            this.loading = false
          })
      },

      async getCompanies(userId) {
        this.loading = true
        axios
          .get(
            process.env.VUE_APP_API_DIRECTORY + 'companies?limit=200&user_id=' + userId.id
          )
          .then((response) => {
            this.companies = response.data.data
            this.loading = false
          })
      },

      async getUsers() {
        this.loading = true
        axios
          .get(process.env.VUE_APP_API_DIRECTORY + 'users?limit=1000')
          .then((response) => {
            this.users = response.data
            this.users.map((x) => {
              x.fullName = x.name + ' ' + x.lastname + ' | ' + x.email
            })
            this.loading = false
          })
      },

      async handleCreate() {
        this.flow = 'create'
        this.item = {}
        this.name = ''
        this.redirection = ''
        this.userId = ''
        this.companyId = ''
        this.selectedCountries = []
        this.type = 'vertical'
        this.outstandingStart = new Date(date.setMonth(date.getMonth() + 1))
          .toISOString()
          .split('T')[0]
        this.outstandingEnd = new Date(date.setMonth(date.getMonth() + 1))
          .toISOString()
          .split('T')[0]
        this.expiratedAt = new Date(date.setMonth(date.getMonth() + 1))
          .toISOString()
          .split('T')[0]
        this.order = 1
        this.active = false

        this.$modal.show('crudModal')
      },

      async handleEdit(item) {
        this.flow = 'edit'
        this.item = item
        this.image = null
        this.name = this.item.name
        this.redirection = this.item.redirection
        this.userId = this.users.find((user) => user.id == this.item.user_id)
        this.companyId = this.item.company_id
        this.type = this.item.type
        this.outstandingStart = new Date(this.item.outstanding_start)
          .toISOString()
          .split('T')[0]
        this.outstandingEnd = new Date(this.item.outstanding_end)
          .toISOString()
          .split('T')[0]
        this.expiratedAt = new Date(this.item.expirated_at).toISOString().split('T')[0]
        this.order = this.item.order
        this.active = this.item.active == 0 ? false : true
        this.selectedCountries = this.item.countries
        this.$modal.show('crudModal')
      },

      validate() {
        this.$refs.form.validate()
      },

      reset() {
        this.$refs.form.reset()
      },

      async createItem() {
        let fileResize = []
        let formData = new FormData()
        this.loading = true
        this.active = this.active ? 1 : 0
        this.config.headers = {
          'Content-Type': 'multipart/form-data',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: this.$session.get('tokenSession')
            ? 'Bearer ' + this.$session.get('tokenSession')
            : 'Bearer ',
        }
        switch (this.type) {
          case 'vertical1':
            formData.append('fileResize[width]', 300)
            formData.append('fileResize[height]', 400)
            break
          case 'vertical2':
            formData.append('fileResize[width]', 250)
            formData.append('fileResize[height]', 250)
            break
          case 'horizontal1':
            formData.append('fileResize[width]', 1920)
            formData.append('fileResize[height]', 540)
            break
          case 'horizontal2':
            formData.append('fileResize[width]', 1920)
            formData.append('fileResize[height]', 300)
            break
          default:
            break
        }
        if (this.image) {
          formData.append('file', this.image)
        }
        formData.append('fileType', 'image')
        formData.append('user_id', this.userId.id)
        formData.append('name', this.name)
        if (this.companyId && this.companyId != '') {
          formData.append('company_id', this.companyId)
        }
        formData.append('redirection', this.redirection)
        formData.append('order', this.order)
        formData.append('type', this.type)
        formData.append(
          'outstanding_start',
          new Date(this.outstandingStart).toISOString()
        )
        formData.append('outstanding_end', new Date(this.outstandingEnd).toISOString())
        formData.append('expirated_at', new Date(this.expiratedAt).toISOString())
        formData.append('active', this.active)
        formData.append('print_count', 0)
        formData.append('print_time', 0)
        formData.append('click_count', 0)
        this.selectedCountries.forEach((country) => {
          formData.append('countries[]', country.id)
        })
        axios
          .post(
            process.env.VUE_APP_API_DIRECTORY + 'advertisements',
            formData,
            this.config
          )
          .then((response) => {
            this.page = 1
            this.getAdvertisements()
            this.$modal.hide('crudModal')
            this.loading = false
          })
      },

      async saveItem() {
        let formData = new FormData()
        this.loading = true
        this.active = this.active ? 1 : 0
        this.config.headers = {
          'Content-Type': 'multipart/form-data',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: this.$session.get('tokenSession')
            ? 'Bearer ' + this.$session.get('tokenSession')
            : 'Bearer ',
        }
        switch (this.type) {
          case 'vertical1':
            formData.append('fileResize[width]', 300)
            formData.append('fileResize[height]', 400)
            break
          case 'vertical2':
            formData.append('fileResize[width]', 250)
            formData.append('fileResize[height]', 250)
            break
          case 'horizontal1':
            formData.append('fileResize[width]', 1920)
            formData.append('fileResize[height]', 540)
            break
          case 'horizontal2':
            formData.append('fileResize[width]', 1920)
            formData.append('fileResize[height]', 300)
            break
          default:
            break
        }
        if (this.image) {
          formData.append('file', this.image)
        }
        if (this.item.image && this.item.image.id) {
          formData.append('fileId', this.item.image.id)
        }
        formData.append('fileType', 'image')
        formData.append('_method', 'patch')
        if (this.userId && this.userId.id) {
          formData.append('user_id', this.userId.id)
        }
        formData.append('name', this.name)
        if (this.companyId && this.companyId != '') {
          formData.append('company_id', this.companyId)
        }
        formData.append('redirection', this.redirection)
        formData.append('order', this.order)
        formData.append('type', this.type)
        formData.append(
          'outstanding_start',
          new Date(this.outstandingStart).toISOString()
        )
        formData.append('outstanding_end', new Date(this.outstandingEnd).toISOString())
        formData.append('expirated_at', new Date(this.expiratedAt).toISOString())
        formData.append('active', this.active)
        this.selectedCountries.forEach((country) => {
          formData.append('countries[]', country.id)
        })
        formData.append('print_count', 0)
        formData.append('print_time', 0)
        formData.append('click_count', 0)

        axios
          .post(
            process.env.VUE_APP_API_DIRECTORY + 'advertisements/' + this.item.id,
            formData,
            this.config
          )
          .then((response) => {
            this.page = 1
            this.getAdvertisements()
            this.$modal.hide('crudModal')
            this.loading = false
          })
      },

      async deleteItem(item) {
        this.loading = true
        axios
          .delete(process.env.VUE_APP_API_DIRECTORY + 'advertisements/' + item.id)
          .then((response) => {
            this.page = 1
            this.items = []
            this.getAdvertisements()
          })
          .catch((error) => {})
          .finally(() => (this.loading = false))
      },

      async pagination(page) {
        this.page = page
        this.items = []
        await this.getAdvertisements()
      },
    },
  }
</script>
<style lang="scss">
  @import '@/assets/scss/cruds/_crudStyles.scss';
</style>
